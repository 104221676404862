<template>
  <div class="p-2">
    <div class="" style="border: 1px solid #ccc;">
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
      />
      <Editor
        style="height: 500px; overflow-y: hidden;"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
      />
    </div>
    <div class="w-full py-2 text-right">
      <a-button type="primary" @click="settingInfoFunc" style="width:200px">
        发布
      </a-button>
    </div>
  </div>
</template>
<script>
import { gettingInfo, settingInfo } from "@/api/system";
import { DomEditor } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import request from "@/utils/request";
import { json } from "body-parser";
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            // 上传图片的配置
            // server: '/common/minio/uploadEditorImg',  //接口地址
            // fieldName: 'file',  //接口参数key
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 2 * 1024 * 1024, // 2M
            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,
            // 自定义上传
            async customUpload(file, insertFn) {
              // JS 语法
              // file 即选中的文件
              //   console.log("customUpload--file", file);
              let data = new FormData();
              data.append("file", file);
              const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN")
                ? JSON.parse(localStorage.getItem("ACCESS_TOKEN"))
                : "";
              data.append("admin_user_id", ACCESS_TOKEN.userId);
              // 自己实现上传，并得到图片 url alt href
              request({
                url: "/admin/AdminSetting/upload",
                method: "POST",
                data,
              }).then((res) => {
                // console.log(res.data);
                if (res.code == 200) {
                  // 最后插入图片
                  insertFn(res.data);
                } else {
                  this.$message.error(res.msg);
                }
              });
            },
          },
        },
      },
      mode: "default", // or 'simple'
      type: 1,
    };
  },
  created() {
    this.type = this.$route.query.type;
  },
  mounted() {
    this.gettingInfoFunc();
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    gettingInfoFunc() {
      gettingInfo({ type: this.type }).then((res) => {
        console.log("this.", this);
        console.log(res);
        if (res.code == 200) {
          this.html = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    settingInfoFunc() {
      if (this.html == "") {
        this.$message.error("请先输入内容");
        return;
      }
      settingInfo({ type: this.type, content: this.html }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.activeKey == 1 ? (this.about = res.data) : (this.use = res.data);
          this.$message.success("设置成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.$nextTick(() => {
        const toolbar = DomEditor.getToolbar(editor);
        const toolbarConfig = toolbar.getConfig();
        toolbarConfig.excludeKeys = ["group-video"]; // 移除视频上传
      });
    },
  },
};
</script>
<style></style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
